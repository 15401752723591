import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TEACHER_DEV_URL, TEACHER_PROD_URL, SCHOOL_DEV_URL, SCHOOL_PROD_URL, SUPER_ADMIN_PROD_URL, SUPER_ADMIN_DEV_URL, MATHS_LAB_TRAINER_URL, MATHS_LAB_ADMIN_URL, MATHS_LAB_SUPER_ADMIN_URL } from '../Constants/Constant';

@Injectable({
  providedIn: 'root'
})
export class SubDomainRouteGuard implements CanLoad {

  constructor(private router: Router) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    const module = segments[0].path;
    const baseUrl = window.location.host;
    switch (baseUrl) {
      case 'localhost:4200':
      case 'shiksha-dev.vercel.app':
      case '192.168.43.131:4200':
        return true;
      case TEACHER_DEV_URL: case TEACHER_PROD_URL: case MATHS_LAB_TRAINER_URL:
        if (module === 'teacher') {
          return true;
        }
        this.router.navigate(['teacher']);
        return false;
      case SCHOOL_DEV_URL: case SCHOOL_PROD_URL: case MATHS_LAB_ADMIN_URL:
        if (module === 'school') {
          return true;
        }
        this.router.navigate(['school']);
        return false;
      case SUPER_ADMIN_DEV_URL: case SUPER_ADMIN_PROD_URL: case MATHS_LAB_SUPER_ADMIN_URL:
        if (module === 'super-admin') {
          return true;
        }
        this.router.navigate(['super-admin']);
        return false;
      default:
        if (module === 'student') {
          return true;
        }
        this.router.navigate(['student']);
        return false;
    }
  }
}
